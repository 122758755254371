import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import headerStyles from './header.module.scss'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

const Header = () => {
  const data = useStaticQuery(graphql`
  query MyQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`)
  return(
    <div className="container">
      <Navbar className={headerStyles.navigation} bg="transparent" variant="dark" expand="lg">
        <Navbar.Brand as={Link} to='/'><h1 className={headerStyles.title}>{data.site.siteMetadata.title}</h1></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to='/download'>Free Download</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to='/contact'>Contact</Link>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default Header